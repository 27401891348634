<template>
    <div>
      <div>
        <HeaderView/>
      </div>
      <div>
        <ContactPageA/>
      </div>
      <!-- <div>
        <ContactPageB/>
      </div>
      <div>
        <ContactPageC/>
      </div> -->
      <div>
        <FooterView />
      </div>
  
    </div>
  </template>
  
  <script>
  import HeaderView from '@/components/HeaderView.vue';
  import ContactPageA from '@/components/ContactPage/ContactPageA.vue';
//   import ContactPageB from '@/components/ContactPage/ContactPageB.vue';
//   import ContactPageC from '@/components/ContactPage/ContactPageC.vue';
  import FooterView from '@/components/FooterView.vue'
    export default {
      components:{
        HeaderView,
        ContactPageA,
        // ContactPageB,
        // ContactPageC,
        FooterView,
  
      },
      
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>