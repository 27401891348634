<template>
  <div>
    <section id="hero" class="hero d-flex flex-column justify-content-center align-items-center text-center dark-background position-relative">
      <img src="@/assets/Home/hero-bg.jpg" alt="" data-aos="fade-in" class="w-100 h-100">
      <div class="container position-absolute top-50 start-50 translate-middle text-white">
        <div class="headerText">
          <h2>Powerful Digital Solutions With My Soft Code<span>.</span></h2>
          <p>We are a team of talented Software Creation</p>
        </div>
        <!-- <div class="row row-cols-1 row-cols-md-3 row-cols-lg-5 gy-4 mt-5"> -->
          <div class="cardItem">
          <div class="cardColumn">
            <div class="card p-3">
              <i class="bi bi-binoculars fs-3"></i>
              <h3><a href="#" class="text text-decoration-none">Vision</a></h3>
            </div>
          </div>
          <div class="cardColumn">
            <div class="card p-3">
              <i class="bi bi-bullseye fs-3"></i>
              <h3><a href="#" class="text text-decoration-none">Target</a></h3>
            </div>
          </div>
          <div class="cardColumn">
            <div class="card p-3">
              <i class="bi bi-fullscreen-exit fs-3"></i>
              <h3><a href="#" class="text text-decoration-none">Best Way</a></h3>
            </div>
          </div>
          <div class="cardColumn">
            <div class="card p-3">
              <i class="bi bi-card-list fs-3"></i>
              <h3><a href="#" class="text text-decoration-none">Collect Info</a></h3>
            </div>
          </div>
          <div class="cardColumn">
            <div class="card p-3">
              <i class="bi bi-gem fs-3"></i>
              <h3><a href="#" class="text text-decoration-none">Archive Gall</a></h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style lang="scss" scoped>
.hero {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.dark-background {
  position: relative;
}

.dark-background img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dark-background::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.dark-background:hover::before {
  opacity: 0.7;
}

.container {
  text-align: center;
  z-index: 1;
}

.headerText {
  margin-top: 150px;
}

h2 {
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
}

p {
  color: rgb(255, 255, 255);
  font-size: 1.25rem;
}

.cardItem{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.card {
  background-color: rgba(137, 43, 226, 0);
  border: 2px solid rgb(0, 247, 255);
  height: 150px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  margin: 10px;
}

.card:hover {
  transform: translateY(-10px);
}

.card i {
  color: rgb(0, 247, 255);
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.card h3 a {
  color: rgb(0, 247, 255);
  font-size: 1rem;
}

@media (max-width: 767px) {
  .dark-background img {
    height: auto;
  }
  
  .cardItem{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .headerText {
    margin-top: 100px;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .card {
    height: 120px;
    width: 150px;
    margin: 10px;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .card i {
    font-size: 1.5rem;
  }

  .card h3 a {
    font-size: 0.875rem;
  }
}

@media (max-width: 500px) {
  .headerText {
    margin-top: 100px;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.875rem;
  }

  .cardItem{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .card {
    height: 100px;
    width: 150px;
  }

  .card i {
    font-size: 1.25rem;
  }

  .card h3 a {
    font-size: 0.75rem;
  }

  @media (max-width: 500px) {
    
    .card {
      height: 100px;
      width: 125px;
  }

  }
}
</style>
