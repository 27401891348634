<template>
  <div>
    <footer id="footer" class="footer dark-background">
      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-6 footer-about">
              <a href="index.html" class="logo d-flex align-items-center">
                <span class="sitename">
                  <img src="@/assets/MySoftCode.png" alt="Logo" width="60" height="60" class="d-inline-block align-text-top">
                </span>
              </a>
              <div class="footer-contact pt-3">
                <p>Main Street</p>
                <p>Colombo, Sri Lanka</p>
                <p class="mt-3"><strong>Phone:</strong> <span>+94 000000000</span></p>
                <p><strong>Email:</strong> <span>info@example.com</span></p>
              </div>
              <div class="social-links d-flex mt-4">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>

            <div class="col-lg-2 col-md-3 footer-links">
              <h4>Get Links</h4>
              <ul>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Home</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="#">About us</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Services</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Terms of service</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Privacy policy</a></li>
              </ul>
            </div>

            <div class="col-lg-2 col-md-3 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Web Design</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Web Development</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Product Management</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Marketing</a></li>
                <li><i class="bi bi-chevron-right"></i> <a href="#">Graphic Design</a></li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-12 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
              <form action="forms/newsletter.php" method="post" class="php-email-form">
                <div class="newsletter-form"><input type="email" name="email"><input type="submit" value="Subscribe"></div>
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your subscription request has been sent. Thank you!</div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="copyright">
        <div class="container text-center">
          <p>© <span>Copyright</span> <strong class="px-1 sitename">MySoftCode</strong> <span>All Rights Reserved</span></p>
          <div class="credits">
            Designed by <a href="https://mysoftcode.com/">MySoftCode</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>


<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.footer {
  color: #ccc;
  background-color: #222;
  font-size: 14px;
  position: relative;

  .footer-top {
    padding: 50px 0;
    background-color: #000000;
  }

  .footer-about .logo {
    line-height: 1;
    margin-bottom: 10px;

    img {
      max-height: 40px;
      margin-right: 6px;
    }

    span {
      font-size: 26px;
      font-weight: 700;
      letter-spacing: 1px;
      color: #fff;
    }
  }

  .footer-contact p {
    font-size: 14px;
    color: #ccc;
  }

  .social-links a {
    color: #ccc;
    background-color: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
    transition: 0.3s;

    &:hover {
      color: #fff;
      background-color: #007bff;
    }
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 12px;
    color: #fff;
  }

  .footer-links {
    margin-bottom: 30px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      i {
        margin-right: 4px;
        font-size: 12px;
        color: #007bff;
      }

      li {
        padding: 10px 0;
        display: flex;
        align-items: center;

        &:first-child {
          padding-top: 0;
        }

        a {
          color: #ccc;
          transition: 0.3s;

          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }

  .footer-newsletter {
    .newsletter-form {
      margin-top: 30px;
      margin-bottom: 15px;
      padding: 6px 8px;
      position: relative;
      border-radius: 4px;
      border: 1px solid #555;
      display: flex;
      background-color: #444;

      &:focus-within {
        border-color: #007bff;
      }

      input[type=email] {
        border: 0;
        padding: 4px;
        width: 100%;
        background-color: #444;
        color: #ccc;

        &:focus-visible {
          outline: none;
        }
      }

      input[type=submit] {
        border: 0;
        font-size: 16px;
        padding: 0 20px;
        margin: -7px -8px -7px 0;
        background: #007bff;
        color: #fff;
        transition: 0.3s;
        border-radius: 0 4px 4px 0;

        &:hover {
          background: #0056b3;
        }
      }
    }
  }

  .copyright {
    padding: 30px 0;

    p {
      margin-bottom: 0;
    }

    .credits {
      margin-top: 5px;
      font-size: 13px;
      color: #ccc;

      a {
        color: #007bff;

        &:hover {
          color: #0056b3;
        }
      }
    }
  }
}
</style>
