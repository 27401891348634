<template>
    <div>
      <div>
        <HeaderView/>
      </div>
      <div>
        <TeamPageA/>
      </div>
      <!-- <div>
        <TeamPageB/>
      </div>
      <div>
        <TeamPageC/>
      </div> -->
      <div>
        <FooterView />
      </div>
  
    </div>
  </template>
  
  <script>
  import HeaderView from '@/components/HeaderView.vue';
  import TeamPageA from '@/components/TeamPage/TeamPageA.vue';
//   import TeamPageB from '@/components/TeamPage/TeamPageB.vue';
//   import TeamPageC from '@/components/TeamPage/TeamPageC.vue';
  import FooterView from '@/components/FooterView.vue'
    export default {
      components:{
        HeaderView,
        TeamPageA,
        // TeamPageB,
        // TeamPageC,
        FooterView,
  
      },
      
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>