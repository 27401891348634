<template>
  <div>
    <div class="index-page">
    <div>
      <HeaderView />
    </div>

      <div>
        <StartView />
      </div>

      <div>
        <AboutPart />
      </div>

      <div>
        <ServicesPart />
      </div>
      <div>
        <ClientPart />
      </div>

      <div>
        <FooterView />
      </div>
    </div>

  </div>
</template>

<script>
import HeaderView from '@/components/HeaderView.vue'
import StartView from '@/components/StartView.vue'
import AboutPart from '@/components/AboutPart.vue'
import ClientPart from '@/components/ClientPart.vue'
import FooterView from '@/components/FooterView.vue'
import ServicesPart from '@/components/ServicesPart.vue'

  export default {
    components:{
      HeaderView,
      StartView,
      AboutPart,
      ServicesPart,
      ClientPart,
      FooterView,
    }
    
  }
</script>

<style lang="scss" scoped>

</style>