<template>
  <div class="classAbout">
    <section id="about" class="about d-flex flex-column justify-content-center align-items-center">
      <div class="container">
        <div class="row row-cols-1 row-cols-md-2 gy-4">
          <div class="col slide-in-left">
            <img src="@/assets/Home/about.jpg" class="img-fluid" alt="About Us">
          </div>
          <div class="col content slide-in-right">
            <h3>About Our Company</h3>
            <p class="fst-italic">
              We are a leading software company specializing in web applications, Windows applications, and mobile applications.
            </p>
            <ul class="list-unstyled">
              <li><i class="bi bi-check2-all"></i> <span>Expertise in developing high-quality web applications.</span></li>
              <li><i class="bi bi-check2-all"></i> <span>Innovative Windows applications that enhance productivity.</span></li>
              <li><i class="bi bi-check2-all"></i> <span>Cutting-edge mobile applications for iOS and Android platforms.</span></li>
            </ul>
            <p>
              Our team of skilled professionals is dedicated to delivering exceptional solutions that meet your business needs. We strive for excellence in every project we undertake, ensuring customer satisfaction and success.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.classAbout{
  width: 100%;
  height: 100%;
}
.about {
  background-color: white;
  padding: 5%;
  overflow: hidden;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: slideInLeft 1s ease-out forwards;
}

.slide-in-right {
  animation: slideInRight 1s ease-out forwards;
}
</style>
